<template>
  <v-row
    align="center"
    id="BoxOptions"
    v-bind:style="[
      {
        position: $vuetify.breakpoint.smAndDown ? 'sticky' : 'relative',
        'z-index': '8',
      },
    ]"
    class="gris4 pa-md-3"
  >
    <!--filtros de calendars -->
    <template v-if="tab">
      <!-- Day Week Selector-->
      <v-btn-toggle :value="btn" mandatory class="square ">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn
              value="day"
              outlined
              v-on="btn === 'day' ? on : null"
              @click="btn = 'day'"
              v-bind:class="[{ '.v-btn--active': btn === 'day' }]"
              >{{ $t("day") }}</v-btn
            >
          </template>
          <v-date-picker
            first-day-of-week="1"
            no-title
            v-model="date"
            color="primary"
          ></v-date-picker>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn
              value="week"
              outlined
              v-on="btn === 'week' ? on : null"
              @click="
                btn = 'week';
                btn === 'week' ? null : (week = date);
              "
              v-bind:class="[{ '.v-btn--active': btn === 'week' }]"
              >{{ $t("week") }}</v-btn
            >
          </template>
          <v-date-picker
            first-day-of-week="1"
            no-title
            v-model="week"
            color="primary"
            range
            class="weekSelector"
          ></v-date-picker>
        </v-menu>
      </v-btn-toggle>

      <!-- FILTROS DE DIA-->
      <template v-if="btn === 'day'">
        <!-- Filtro de FECHA -->
        <v-col
          cols="6"
          sm="2"
          v-bind:style="[{ order: $vuetify.breakpoint.xs ? 1 : null }]"
        >
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                outlined
                dense
                hide-details
                :label="$t('expenses.data')"
                :value="date ? $d(new Date(date), 'dateShort') : null"
              />
            </template>
            <v-date-picker
              no-title
              first-day-of-week="1"
              v-model="date"
              color="primary"
            />
          </v-menu>
        </v-col>
        <!-- Show customer - Tattooer -->
        <template v-if="$store.getters['auth/isStudio']">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                value="list"
                fab
                outlined
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="tab_day = true"
                x-small
                v-bind:class="[
                  'mx-1',
                  {
                    active: tab_day === true,
                  },
                ]"
              >
                <v-icon size="15">$customers</v-icon>
              </v-btn></template
            >
            <span>{{ $tc("appointment") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                outlined
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="tab_day = false"
                x-small
                v-bind:class="[
                  'mx-1',
                  {
                    active: tab_day === false,
                  },
                ]"
              >
                <v-icon size="15">$tattooers</v-icon>
              </v-btn>
            </template>
            <span>{{ $tc("artist") }}</span>
          </v-tooltip>
        </template>
      </template>
      <template v-else>
        <!-- Filtro de tatuador -->

        <v-col
          cols="6"
          sm="2"
          class="py-0"
          v-bind:style="[{ order: $vuetify.breakpoint.xs ? 1 : null }]"
          v-if="$store.getters['auth/isStudio']"
        >
          <v-select
            v-if="$store.getters['auth/isStudio']"
            outlined
            dense
            :label="$t('artist')"
            hide-details
            :items="tattooers"
            v-model="filtersWeek.tattooer"
            @input="
              filtersWeek.box = null;
              tab_week = true;
            "
            item-value="id"
            :item-text="(x) => `${x.user.first_name} ${x.user.last_name || ''}`"
            clearable
          />
        </v-col>

        <!-- Filtro de boxes -->

        <v-col
          cols="6"
          sm="2"
          class="py-0"
          v-bind:style="[{ order: $vuetify.breakpoint.xs ? 1 : null }]"
          v-if="$store.getters['auth/isStudio']"
        >
          <v-select
            outlined
            dense
            :label="$t('boxes.boxs')"
            hide-details
            clearable
            item-value="id"
            v-model="filtersWeek.box"
            @input="
              filtersWeek.tattooer = null;
              tab_week = true;
            "
            item-text="name"
            :items="boxes"
          />
        </v-col>
        <template v-if="$store.getters['auth/isStudio']">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                value="list"
                fab
                outlined
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="tab_week = true"
                x-small
                v-bind:class="[
                  'mx-1',
                  {
                    active: tab_week === true,
                  },
                ]"
              >
                <v-icon size="15">$calendar</v-icon>
              </v-btn></template
            >
            <span>{{ $tc("calendar") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                outlined
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="tab_week = false"
                x-small
                v-bind:class="[
                  'mx-1',
                  {
                    active: tab_week === false,
                  },
                ]"
                :disabled="filtersWeek.box || filtersWeek.tattooer"
              >
                <v-icon size="15">mdi-percent</v-icon>
              </v-btn>
            </template>
            <span>{{ $tc("boxes.percentage") }}</span>
          </v-tooltip>
        </template>
      </template>
    </template>
    <!--FILTROS PARA LISTADO-->
    <template v-else>
      <v-col cols="6" sm="2">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              outlined
              dense
              hide-details
              :label="$t('expenses.data')"
              :value="date ? $d(new Date(date), 'dateShort') : null"
            />
          </template>
          <v-date-picker
            no-title
            first-day-of-week="1"
            v-model="date"
            color="primary"
          />
        </v-menu>
      </v-col>
    </template>
    <template v-if="$store.getters['auth/isTattooer']">
      <v-col
        cols="6"
        sm="2"
        v-bind:style="[{ order: $vuetify.breakpoint.xs ? 1 : null }]"
      >
        <v-select
          :items="studios"
          v-model="studio_id"
          item-value="id"
          item-text="studio_name"
          outlined
          dense
          :label="$tc('studio')"
          hide-details
          @input="
            $emit('reload');
            fetchBoxes();
            fetchTimeStudio();
          "
        ></v-select>
      </v-col>
    </template>
    <v-spacer></v-spacer>
    <!-- tab selector (list - calendars) -->
    <template>
      <v-btn
        class="mx-1"
        value="day"
        fab
        :outlined="tab == false"
        x-small
        @click="tab = true"
        elevation="0"
        :disabled="boxes.length === 0"
      >
        <v-icon small>$square</v-icon>
      </v-btn>
      <v-btn
        class="mx-1"
        value="week"
        fab
        :outlined="tab == true"
        x-small
        @click="tab = false"
        elevation="0"
      >
        <v-icon small>$list</v-icon>
      </v-btn>
    </template>

    <AddMenu
      @reload="
        $emit('reload');
        fetchFilters();
      "
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "BoxOptions",
  components: {
    AddMenu: () => import("@/components/boxes/components/AddMenu"),
  },
  data() {
    return {};
  },
  mounted() {
    this.fetchFilters();
    this.week = this.today;
  },
  computed: {
    ...mapGetters("boxes", ["today"]),
    ...mapState("boxes", [
      "tabs",
      "filtersState",
      "filtersWeekState",
      "boxState",
      "dateState",
      "tattooers",
      "studios",
      "boxes",
    ]),
    /**
     * Options
     */
    btn: {
      get() {
        return this.tabs.btn;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "btn" });
      },
    },
    tab: {
      get() {
        return this.tabs.tab;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab" });
      },
    },
    tab_day: {
      get() {
        return this.tabs.tab_day;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab_day" });
      },
    },
    tab_week: {
      get() {
        return this.tabs.tab_week;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab_week" });
      },
    },
    /**
     * Filters
     */
    filters: {
      get() {
        return this.filtersState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS", value);
      },
    },
    filtersWeek: {
      get() {
        return this.filtersWeekState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS_WEEK", value);
      },
    },
    date: {
      get() {
        return this.dateState;
      },
      set(value) {
        this.$store.commit("boxes/SET_DATE", value);
      },
    },
    week: {
      get() {
        //return [];
        return [this.filters.from, this.filters.to];
      },
      set(value) {
        this.dates = [];
        if (typeof value === "Array") value = value[0];
        let valor = value;
        this.dates.push(valor);
        value = new Date(value);

        let d = value.getDate() + 1;
        let di = value.getDay() === 0 ? 7 : value.getDay();

        value.setDate(d - di);

        this.filters.from = value.toISOString().slice(0, 10);

        value.setDate(value.getDate() + 6);
        this.filters.to = value.toISOString().slice(0, 10);
      },
    },
    studio_id: {
      get() {
        return this.$store.state.boxes.studioId;
      },
      set(value) {
        this.$store.commit("boxes/SET_STUDIO_ID", value);
      },
    },
  },
  methods: {
    fetchFilters() {
      if (this.$store.getters["auth/isStudio"]) {
        this.studio_id = this.$store.state.auth.user.studio.id;
        this.$emit("load");
        this.fetchTattooers();
        this.fetchBoxes();
        this.fetchTimeStudio();
      } else {
        this.fetchStudios();
      }
    },
    ...mapActions("studios", ["getStudioTattooersByDate", "getStudio"]),
    fetchTattooers() {
      this.getStudioTattooersByDate({
        studio_id: this.$store.state.boxes.studioId,
        date: this.date,
      }).then((data) => {
        let ts = data.map((x) => {
          return { ...x.tattooer, timetable: x.tattooer_timetable };
        });
        this.$store.commit("boxes/SET_TATTOOERS", ts);
      });
    },

    fetchTimeStudio() {
      this.getStudio(this.$store.state.boxes.studioId).then((data) => {
        this.$store.commit("boxes/SET_TIMETABLE_STUDIO", data.timetable);
      });
    },
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      this.getBoxes({
        pagination: {
          itemsPerPage: -1,
          studio_id: this.$store.state.boxes.studioId,
        },
      }).then((data) => {
        this.$store.commit("boxes/SET_BOXES", data.data);
        if (data.total === 0) {
          this.tab = false;
        }
      });
    },
    ...mapActions("tattooers", ["getTattooerStudiosByDate"]),
    fetchStudios() {
      this.getTattooerStudiosByDate({
        tattooer_id: this.$store.state.auth.user.tattooer.id,
        date: this.date,
      }).then((data) => {
        let ts = data.map((x) => {
          return { ...x.studio, timetable: x.tattooer_timetable };
        });
        this.$store.commit("boxes/SET_STUDIOS", ts);
        if (data.length > 0) {
          let rel = data.find((rel) => {
            console.log(rel);
            //return true;
            return (
              rel.studio.user.setting.length > 0 &&
              rel.studio.user.setting[0].value == 1
            );
          });

          console.log("rel", rel);
          if (rel && !this.studio_id) this.studio_id = rel.studio.id;
          else this.studio_id = data[0].studio.id;
          this.$emit("load");
        }
        this.$nextTick(() => {
          console.log(this.studios);
          this.fetchBoxes();
          this.fetchTimeStudio();
        });
      });
    },
  },
};
</script>
<style lang="sass">
.weekSelector
  .theme--dark.v-btn:hover::before
    opacity: 0
  tr
    &:hover
      td
        background-color: var(--v-primary-base)
        border-radius: 0
        border: 1px solid var(--v-primary-base)
        &:first-child
          border-top-left-radius: 50%
          border-bottom-left-radius: 50%
        &:last-child
          border-top-right-radius: 50%
          border-bottom-right-radius: 50%
        .v-btn__content
          color: black !important

    td
      border-radius: 50px

      &:first-child
        border-left: thin var(--v-primary-base) solid

      &:last-child
        border-right: thin var(--v-primary-base) solid
#BoxOptions
  .v-label
    color: var(--v-primary-base) !important

  .v-btn
    &.v-btn--fab.v-btn--outlined
      &.active
        border-width: 3px
  .v-btn-toggle
    &.square
      .v-btn
        height: 30px !important
        @media (max-width: 960px)
          width: 10px
    &.circle
      .v-btn
        height: 40px !important
        width: 40px !important
        border-radius: 50px !important
        min-width: unset !important

    .v-btn.v-btn--active
      color: black !important
      border-color: var(--v-primary-base) !important
      background-color: var(--v-primary-base) !important
</style>
