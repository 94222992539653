var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"gris4 pa-md-3",style:([
    {
      position: _vm.$vuetify.breakpoint.smAndDown ? 'sticky' : 'relative',
      'z-index': '8',
    } ]),attrs:{"align":"center","id":"BoxOptions"}},[(_vm.tab)?[_c('v-btn-toggle',{staticClass:"square ",attrs:{"value":_vm.btn,"mandatory":""}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({class:[{ '.v-btn--active': _vm.btn === 'day' }],attrs:{"value":"day","outlined":""},on:{"click":function($event){_vm.btn = 'day'}}},_vm.btn === 'day' ? on : null),[_vm._v(_vm._s(_vm.$t("day")))])]}}],null,false,2074268914)},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","color":"primary"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({class:[{ '.v-btn--active': _vm.btn === 'week' }],attrs:{"value":"week","outlined":""},on:{"click":function($event){_vm.btn = 'week';
              _vm.btn === 'week' ? null : (_vm.week = _vm.date);}}},_vm.btn === 'week' ? on : null),[_vm._v(_vm._s(_vm.$t("week")))])]}}],null,false,3678557419)},[_c('v-date-picker',{staticClass:"weekSelector",attrs:{"first-day-of-week":"1","no-title":"","color":"primary","range":""},model:{value:(_vm.week),callback:function ($$v) {_vm.week=$$v},expression:"week"}})],1)],1),(_vm.btn === 'day')?[_c('v-col',{style:([{ order: _vm.$vuetify.breakpoint.xs ? 1 : null }]),attrs:{"cols":"6","sm":"2"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('expenses.data'),"value":_vm.date ? _vm.$d(new Date(_vm.date), 'dateShort') : null}},on))]}}],null,false,525004316)},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1","color":"primary"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),(_vm.$store.getters['auth/isStudio'])?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[
                'mx-1',
                {
                  active: _vm.tab_day === true,
                } ],attrs:{"value":"list","fab":"","outlined":"","elevation":"0","x-small":""},on:{"click":function($event){_vm.tab_day = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("$customers")])],1)]}}],null,false,1350598209)},[_c('span',[_vm._v(_vm._s(_vm.$tc("appointment")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[
                'mx-1',
                {
                  active: _vm.tab_day === false,
                } ],attrs:{"fab":"","outlined":"","elevation":"0","x-small":""},on:{"click":function($event){_vm.tab_day = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("$tattooers")])],1)]}}],null,false,3877132504)},[_c('span',[_vm._v(_vm._s(_vm.$tc("artist")))])])]:_vm._e()]:[(_vm.$store.getters['auth/isStudio'])?_c('v-col',{staticClass:"py-0",style:([{ order: _vm.$vuetify.breakpoint.xs ? 1 : null }]),attrs:{"cols":"6","sm":"2"}},[(_vm.$store.getters['auth/isStudio'])?_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('artist'),"hide-details":"","items":_vm.tattooers,"item-value":"id","item-text":function (x) { return ((x.user.first_name) + " " + (x.user.last_name || '')); },"clearable":""},on:{"input":function($event){_vm.filtersWeek.box = null;
            _vm.tab_week = true;}},model:{value:(_vm.filtersWeek.tattooer),callback:function ($$v) {_vm.$set(_vm.filtersWeek, "tattooer", $$v)},expression:"filtersWeek.tattooer"}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/isStudio'])?_c('v-col',{staticClass:"py-0",style:([{ order: _vm.$vuetify.breakpoint.xs ? 1 : null }]),attrs:{"cols":"6","sm":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('boxes.boxs'),"hide-details":"","clearable":"","item-value":"id","item-text":"name","items":_vm.boxes},on:{"input":function($event){_vm.filtersWeek.tattooer = null;
            _vm.tab_week = true;}},model:{value:(_vm.filtersWeek.box),callback:function ($$v) {_vm.$set(_vm.filtersWeek, "box", $$v)},expression:"filtersWeek.box"}})],1):_vm._e(),(_vm.$store.getters['auth/isStudio'])?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[
                'mx-1',
                {
                  active: _vm.tab_week === true,
                } ],attrs:{"value":"list","fab":"","outlined":"","elevation":"0","x-small":""},on:{"click":function($event){_vm.tab_week = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("$calendar")])],1)]}}],null,false,1192440836)},[_c('span',[_vm._v(_vm._s(_vm.$tc("calendar")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[
                'mx-1',
                {
                  active: _vm.tab_week === false,
                } ],attrs:{"fab":"","outlined":"","elevation":"0","x-small":"","disabled":_vm.filtersWeek.box || _vm.filtersWeek.tattooer},on:{"click":function($event){_vm.tab_week = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-percent")])],1)]}}],null,false,1017942058)},[_c('span',[_vm._v(_vm._s(_vm.$tc("boxes.percentage")))])])]:_vm._e()]]:[_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('expenses.data'),"value":_vm.date ? _vm.$d(new Date(_vm.date), 'dateShort') : null}},on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1","color":"primary"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],(_vm.$store.getters['auth/isTattooer'])?[_c('v-col',{style:([{ order: _vm.$vuetify.breakpoint.xs ? 1 : null }]),attrs:{"cols":"6","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.studios,"item-value":"id","item-text":"studio_name","outlined":"","dense":"","label":_vm.$tc('studio'),"hide-details":""},on:{"input":function($event){_vm.$emit('reload');
          _vm.fetchBoxes();
          _vm.fetchTimeStudio();}},model:{value:(_vm.studio_id),callback:function ($$v) {_vm.studio_id=$$v},expression:"studio_id"}})],1)]:_vm._e(),_c('v-spacer'),[_c('v-btn',{staticClass:"mx-1",attrs:{"value":"day","fab":"","outlined":_vm.tab == false,"x-small":"","elevation":"0","disabled":_vm.boxes.length === 0},on:{"click":function($event){_vm.tab = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$square")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"value":"week","fab":"","outlined":_vm.tab == true,"x-small":"","elevation":"0"},on:{"click":function($event){_vm.tab = false}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$list")])],1)],_c('AddMenu',{on:{"reload":function($event){_vm.$emit('reload');
      _vm.fetchFilters();}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }